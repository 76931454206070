
  export default {
    data() {
      return {
        show: false,
        message: null,
        color: null
      }
    },
    created() {
      this.$store.subscribe((mutation, state) => {
        if (mutation.type === 'snackbar/showMessage') {
          this.message = state.snackbar.content
          this.color = state.snackbar.color
          this.show = true
        }
      })
    }
  }
