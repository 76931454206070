import { files, file_url } from '@/plugins/serverless'

export const load_photos = async pyld => {
  const path = `codi/${pyld.type}_photos/${pyld.id}`

  const directory = await files(path)
  const photos = []
  await Promise.all(
    directory.items.map(async item => {
      photos.push({
        name: item.name,
        path: item.fullPath,
        url: await file_url(item.fullPath)
      })
    })
  )
  photos.sort(first_in_first)
  return photos
}
export const first_in_first = (first, second) => {
  const first_as_number = parseInt(first.name.split('.')[0])
  const second_as_number = parseInt(second.name.split('.')[0])
  return first_as_number - second_as_number
}
