import {
  stories,
  doc,
  ref_doc,
  set_doc,
  savable_date,
  list,
  load,
  update,
  remove,
  file_remove,
  where,
  query,
  listen
} from '@/plugins/serverless.js'
import { load_photos } from '@/plugins/utils'
import { debounce } from '@/functions/use/utils'
import { is_completed_story } from '@/functions/use/story'
import { hydrate_list } from '@/store/utils'

export const actions = {
  reset_story({ commit }) {
    commit('set_story', null)
  },
  async find_story({ commit }, pyld) {
    const snapshot = await list(by_project_author(pyld.project, pyld.author))
    if (!snapshot.empty) {
      const story_list = hydrate_list(snapshot)
      if (story_list.length > 1)
        console.warn('to many stories for this user', story_list)
      const story = story_list[0]
      commit('set_story', story)
    }
  },
  async create_story({ commit }, pyld) {
    const snapshot = ref_doc(stories)
    const story = {
      id: snapshot.id,
      status: 'draft',
      created: savable_date(),
      project: doc(`/projects/${pyld.project}`),
      author: doc(`/users/${pyld.author}`),
      author_name: pyld.author_name
    }
    await set_doc(snapshot, story)
    commit('set_story', story)
  },
  async load_story({ commit }, pyld) {
    const snap = await load(doc(`stories/${pyld.id}`))
    commit('set_story', snap.data())
  },
  async load_photos({ commit }, pyld) {
    const photos = await load_photos({ type: 'story', id: pyld.id })
    commit('set_photos', photos)
  },
  async load_stories({ commit }, id) {
    const stories = await list(by_project(id))
    commit('set_stories', hydrate_list(stories))
  },
  async update_story({ commit }, story) {
    debounce(async () => {
      await update(doc(`stories/${story.id}`), story)
      commit('set_story', story)
    })
  },
  async update_title({ commit, getters }, pyld) {
    if (!pyld.id) return
    await update(doc(`stories/${pyld.id}`), { title: pyld.title })
    const story = getters.get_story
    story.title = pyld.title
    commit('set_story', story)
  },
  async update_content({ commit, getters }, pyld) {
    if (pyld.id)
      debounce(async () => {
        await update(doc(`stories/${pyld.id}`), { content: pyld.content })
        const story = getters.get_story
        story.content = pyld.content
        commit('set_story', story)
      })
  },
  async delete_story(ignore, pyld) {
    remove(doc(`stories/${pyld.id}`))
  },
  async delete_photo({ commit }, pyld) {
    file_remove(`codi/story_photos/${pyld.id}/${pyld.file.name}`)
    commit('set_photos', [])
  }
}
export const state = () => ({
  story: null,
  predictions: [],
  stories: [],
  photos: []
})
export const mutations = {
  set_stories(state, stories) {
    state.stories = stories
  },
  set_predictions(state, predictions) {
    state.predictions = predictions
  },
  set_user_stories(state, stories) {
    state.stories = stories
  },
  set_story(state, story) {
    state.story = story
  },
  set_photos(state, photos) {
    state.photos = photos
  }
}
export const getters = {
  get_story_details: state => state.story_details,
  get_story: state => state.story,
  get_stories: state => state.stories,
  get_recent_predictions: state => state.predictions,
  get_completed_stories: state => {
    const completed = []
    state.stories?.forEach(story => {
      if (is_completed_story(story)) completed.push(story)
    })
    return completed
  },
  get_photos: state => state.photos
}

export const by_project_author = (project, author) =>
  query(
    stories,
    where('project', '==', doc(`/projects/${project}`)),
    where('author', '==', doc(`/users/${author}`))
  )

export const by_project = id =>
  query(stories, where('project', '==', doc(`/projects/${id}`)))
