import {
  companies,
  projects,
  users,
  ref_doc,
  set_doc,
  doc,
  savable_date,
  update,
  load,
  list,
  query,
  where
} from '@/plugins/serverless'
import { hydrate_list } from '@/store/utils'

export const actions = {
  async create({ commit }, pyld) {
    const ref = ref_doc(companies)
    pyld.company.created = savable_date()
    pyld.company.id = ref.id
    const company = pyld.company
    await set_doc(ref, company)
    commit('set_company', company)
  },
  async list({ commit }, pyld) {
    var cos = []
    if (pyld.all) {
      const snap = await list(companies)
      snap.docs.forEach(d => cos.push(d))
      commit('set_companies', hydrate_list(cos))
    } else {
      const snap = await list(by_users(pyld.id))
      snap.docs.forEach(d => cos.push(d))
      commit('set_companies', hydrate_list(cos))
    }
  },
  async load({ commit }, id) {
    const snap = await load(doc(`companies/${id}`))
    if (snap.exists()) commit('set_company', snap.data())
  },
  async load_projects({ commit }, pyld) {
    if (pyld.companyIds?.length) {
      const snap = await list(by_projects(pyld.companyIds))
      if (!snap.empty) commit('set_projects', hydrate_list(snap))
    }
  },
  async load_users({ commit }, pyld) {
    let users = []
    let coIds = []

    if (pyld.sponsors?.length) {
      const snp = await list(by_name(pyld.sponsors))
      if (!snp.empty) snp.forEach(doc => coIds.push(doc.id))
      if (coIds.length) {
        const snap = await list(by_users(coIds))
        users = hydrate_list(snap)
      }
    } else {
      if (pyld.companyIds?.length > 0) {
        const snap = await list(by_users(pyld.companyIds))
        users = hydrate_list(snap)
      }
    }
    commit('set_users', users)
  },
  async update(ignore, company) {
    if (company.id) {
      await update(doc(`companies/${company.id}`), company)
      this.$notifier.showMessage({
        content: `${company.name} updated`,
        color: 'primary'
      })
    }
  },
  async update_logo({ commit }, pyld) {
    await update(doc(`companies/${pyld.id}`), { logo: pyld.url })
    const snap = await load(doc(`companies/${pyld.id}`))
    commit('set_company', snap.data())
  }
}
export const state = () => ({
  company: {},
  companies: [],
  projects: [],
  users: []
})
export const mutations = {
  set_company(state, company) {
    state.company = company
  },
  set_companies(state, companies) {
    state.companies = companies
  },
  set_users(state, users) {
    state.users = users
  },
  set_projects(state, projects) {
    state.projects = projects
  }
}
export const getters = {
  get_company: state => state.company,
  get_companies: state => state.companies,
  get_users: state => state.users,
  get_projects: state => state.projects
}
export const by_staff = id =>
  query(companies, where('staff', 'array-contains', id))

export const by_projects = ids =>
  query(projects, where('companies', 'array-contains-any', ids))

export const by_users = ids =>
  query(users, where('companyIds', 'array-contains-any', ids))

export const by_companies = ids => query(companies, where('id', 'in', ids))

export const by_name = names => query(companies, where('name', 'in', names))
