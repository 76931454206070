import axios from 'axios'
export let bad_words = undefined
const dirty_list_url =
  'https://raw.githubusercontent.com/shutterstock/List-of-Dirty-Naughty-Obscene-and-Otherwise-Bad-Words/master/en'

export const setup_bad_words = async () => {
  const our_bad_words = [
    'phuck',
    'beaver',
    'prick',
    'cooche',
    'cooch',
    'coochie',
    'shart',
    'fart'
  ]
  bad_words = await axios.get(dirty_list_url)
  bad_words = bad_words.data.split('\n')

  bad_words.forEach(words => {
    if (words.includes(' ')) {
      const split_up = words.split(' ')
      const joined = split_up.join('-')
      const mushed = split_up.join('')
      our_bad_words.push(joined)
      our_bad_words.push(mushed)
    } else our_bad_words.push(words)
  })
  bad_words = our_bad_words
}

export const roles_check = (person, roles) => {
  let visibility = false
  if (person?.access) {
    if (person.access.includes('Master Admin')) return true
    if (!roles) return false //an empty roles check can only be true for master admin
    for (const txt of roles) {
      if (person.access.includes(txt)) {
        visibility = true
        break
      }
    }
  }
  return visibility
}
