/* global process */
import { initializeApp, getApps, getApp } from 'firebase/app'
import {
  getAuth,
  signOut,
  RecaptchaVerifier,
  signInWithPhoneNumber
} from 'firebase/auth'
import {
  query as f_query,
  where as f_where,
  limit as f_limit,
  orderBy,
  doc as f_doc,
  collection,
  updateDoc,
  getDocs,
  getDoc,
  setDoc,
  deleteDoc,
  onSnapshot,
  serverTimestamp,
  initializeFirestore
} from 'firebase/firestore'
import {
  getStorage,
  ref,
  listAll,
  getDownloadURL,
  deleteObject,
  uploadBytes
} from 'firebase/storage'
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator
} from 'firebase/functions'
import { hydrate_user } from '@/store/user'
let keys = {
  apiKey: process.env.API_KEY,
  authDomain: process.env.AUTH_DOMAIN,
  databaseUrl: process.env.DATABASE_URL,
  projectId: process.env.PROJECT_ID,
  storageBucket: process.env.STORAGE_BUCKET,
  messagingSenderId: process.env.MESSAGING_SENDER_ID,
  measurementId: process.env.MEASUREMENT_ID
}
const app = getApps().length === 0 ? initializeApp(keys) : getApp()

export const db = initializeFirestore(app, {
  ignoreUndefinedProperties: true,
  merge: true
})

const functions = getFunctions()
if (location.hostname === 'localhost') {
  connectFunctionsEmulator(functions, '127.0.0.1', 5001)
}

export const storage = getStorage(app)
export const callable = name => httpsCallable(functions, name)
export const users = collection(db, 'users')
export const projects = collection(db, 'projects')
export const plans = collection(db, 'plans')
export const stories = collection(db, 'stories')
export const companies = collection(db, 'companies')
export const predictions = collection(db, 'predictions')
export const doc = id => f_doc(db, id) // assumes id will reference collection /users/scott-fryxell
export const ref_doc = f_doc
export const set_doc = setDoc
export const load = reference => getDoc(reference)
export const list = getDocs
export const files = async path => listAll(ref(storage, path))
export const file_url = path => getDownloadURL(ref(storage, path))
export const file_upload = (path, file) => uploadBytes(ref(storage, path), file)
export const file_remove = async path => deleteObject(ref(storage, path))
export const update = updateDoc
export const remove = deleteDoc
export const sign_out = () => {
  signOut(auth)
  sessionStorage.removeItem('relations')
  sessionStorage.removeItem('project')
}
export const order_by = orderBy
export const limit = f_limit
export const where = f_where
export const query = f_query
export const server_time = serverTimestamp
export const listen = onSnapshot

export const auth = getAuth()
export const verifier = RecaptchaVerifier
export const sign_in_mobile = signInWithPhoneNumber
auth.useDeviceLanguage()

export const savable_date = () => server_time()
export default context => {
  const { store } = context
  return new Promise(resolve => {
    auth.onAuthStateChanged(person => {
      if (person) {
        hydrate_user(person.phoneNumber).then(me => {
          if (!me) return
          onSnapshot(doc(`users/${me.id}`), changed => {
            resolve(store.commit('user/setUser', changed.data()))
          })
        })
      } else {
        resolve(store.commit('user/setUser', null))
      }
    })
  })
}
