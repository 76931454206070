import { callable } from '@/plugins/serverless'
import { roles_check } from '@/functions/use/user'
import { mapGetters as getters, mapActions as actions } from 'vuex'
import {
  findPhoneNumbersInText as phone_info,
  AsYouType as phone_format
} from 'libphonenumber-js'

export default {
  data() {
    return {
      required_rules: [v => !!v || 'is required'],
      required_rules_multi: [v => v.length > 0 || 'multi is required'],
      search: '',
      company: null,
      searchType: ['Project', 'User', 'Company'],
      searchParps: false,
      selectedSearchParps: [],
      selectedType: null,
      selectedStatus: 'All',
      selectedSearch: null,
      addFileDiag: false,
      closeOnClick: true,
      selectedParp: '',
      markdown_config: {
        mermaid: {
          theme: 'dark'
        },
        markdownIt: {
          xhtmlOut: false,
          breaks: true,
          html: true,
          linkify: true,
          typographer: true
        }
      }
    }
  },
  computed: {
    ...getters({
      user: 'user/getUser',
      me: 'user/getUser'
    }),
    initials() {
      return this.make_initials(this.user.username)
    }
  },
  methods: {
    log() {
      console.log(...arguments)
      callable('log')(JSON.parse(JSON.stringify(Object.values(arguments))))
    },
    ...actions({
      leave_app: 'user/signout'
    }),
    async home_base() {
      if (this.roles(['Sponsor', 'Recruiter', 'Researcher']))
        this.$router.replace('/projects')
      else this.$router.replace('/users')
    },
    setup_color_scheme() {
      if (window.matchMedia) {
        const is_dark = this.$vuetify.theme.dark
        if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
          if (!is_dark) this.$vuetify.theme.dark = true
        } else if (is_dark) this.$vuetify.theme.dark = false
        window
          .matchMedia('(prefers-color-scheme: dark)')
          .addEventListener('change', event => {
            const is_dark = this.$vuetify.theme.dark
            if (event.matches && !is_dark) this.$vuetify.theme.dark = true
            else if (is_dark) this.$vuetify.theme.dark = false
          })
      }
    },
    make_initials(name) {
      let n = name
      if (typeof name === `string`) {
        n = name
        let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu')
        let initials = [...n.matchAll(rgx)] || []
        initials = (
          (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
        ).toUpperCase()
        return initials
      }
    },
    roles(roles) {
      return roles_check(this.user, roles)
    },
    word_count(item) {
      return item.content?.split(' ').length
    },
    async signout() {
      await this.leave_app()
      this.$router.push('/')
    },
    format_phone(phone_string) {
      if (phone_string) {
        const phones = phone_info(phone_string)
        if (phones.length > 0) {
          const country = phones[0].number.country
          const number = phones[0].number.nationalNumber
          return new phone_format(country).input(number)
        }
      }
      return ''
    },
    format_date(time) {
      if (!time) return ''
      let formatable = time
      if (time.toDate) formatable = time.toDate()

      const output = formatable.toLocaleString('en-US', {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      })
      return output
    },
    format_date_short(time) {
      if (!time) return ''
      let formatable = time
      if (time.toDate) formatable = time.toDate()

      const output = formatable.toLocaleString('en-US', {
        month: 'long',
        year: 'numeric'
      })
      return output
    },

    delete_chip(item, array) {
      array.splice(array.indexOf(item), 1)
      array = [...array]
    },
    notify(txt, color) {
      this.$notifier.showMessage({ content: txt, color: color })
    }
  }
}
