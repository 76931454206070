import {
  users,
  auth,
  ref_doc,
  set_doc,
  doc,
  savable_date,
  list,
  listen,
  load,
  update,
  where,
  order_by,
  limit,
  query,
  sign_out
} from '@/plugins/serverless.js'
import { hydrate_list } from '@/store/utils'
import { dedupe_parps } from '@/functions/use/project'
import { debounce } from '@/functions/use/utils'

export const actions = {
  async update_company_staff(ignore, company) {
    company.staff.forEach(async person => {
      const snap = await load(doc(`users/${person.id}`))
      if (snap.exists()) {
        const user = snap.data()
        if (role_check(['Master no Admin'], user)) return
        else if (person.sponsor) {
          if (!role_check(['Sponsor'], user)) {
            user.access.push('Sponsor')
            await update(doc(`users/${person.id}`), {
              access: user.access
            })
          }
        } else {
          let is_other_sponsor = false
          user.companyIds.forEach(async id => {
            if (id != company.id) {
              const other_company = (
                await load(doc(`users/${person.id}`))
              ).data()
              other_company.staff.forEch(s => {
                if (s.id == user.id && s.sponsor) is_other_sponsor = true
              })
            }
          })
          if (!is_other_sponsor)
            await update(doc(`users/${person.id}`), { access: ['Participant'] })
        }
      }
    })
  },
  async getUserDeats({ commit }, pyld) {
    const snap = await load(doc(`users/${pyld.id}`))
    if (snap.exists()) commit('setUserDeats', snap.data())
  },
  async getHeadshot({ commit, getters }, pyld) {
    console.log('getHeadshot', pyld.id, getters.getUserDeats)
    const snap = await load(doc(`users/${pyld.id}`))
    if (snap.exists() && snap.data()?.headshot) {
      const user = snap.data()
      commit('setHeadshot', user.headshot)
      commit('setUser', user)
    }
  },
  async updateAvatar(ignore, pyld) {
    if (!pyld.id.empty) update(doc(`users/${pyld.id}`), { headshot: pyld.url })
  },
  async updateUser({ commit }, pyld) {
    const user = pyld.userDeats
    if (user?.id) {
      await update(doc(`users/${user.id}`), user)
      commit('setUser', user)
    }
  },
  async create_user({ commit }, pyld) {
    const ref = ref_doc(users)

    const new_user = {
      active: 'New',
      access: ['Participant'],
      id: ref.id,
      phone: pyld.phone,
      country: pyld.country,
      created: savable_date(),
      social: {}
    }
    await set_doc(ref, new_user)
    commit('setUser', new_user)
    listen(doc(`users/${new_user.id}`), changed =>
      commit('setUser', changed.data())
    )
  },
  async username_status({ commit }, pyld) {
    var unique = 'unavailable'
    var naughty = false
    if (pyld && pyld.username) {
      const snap = await list(by_username(pyld.username))
      if (snap.empty == true) unique = 'clean'
    }
    commit('username_status', unique)
  },
  async email_status({ commit }, email) {
    const existing_email = query(users, where('email', '==', email))
    const snap = await list(existing_email)
    commit('email_status', snap.empty)
  },
  async signout({ commit }) {
    this.$notifier.showMessage({ content: 'Signed out', color: 'primary' })
    await sign_out()
    commit('resetUser')
  },
  async signon({ commit, dispatch }, person) {
    const maybe_a_user = query(
      users,
      where('phone', '==', auth.currentUser.phoneNumber)
    )
    const snap = await list(maybe_a_user)
    if (snap.empty) {
      return dispatch('create_user', {
        country: person.country,
        phone: auth.currentUser.phoneNumber
      })
    } else {
      const user = snap.docs[0].data()
      commit('setUser', user)
      this.$notifier.showMessage({ content: 'Welcome back', color: 'primary' })
    }
  },
  async load_new_users({ commit }) {
    const recent = query(
      users,
      where('active', '==', 'Y'),
      limit(100),
      order_by('created', 'desc')
    )
    const snap = await list(recent)
    commit('setUsers', hydrate_list(snap))
  },
  async search_usernames({ commit, getters }, search) {
    debounce(async () => {
      console.log('searching for', search)
      const starts_with = query(
        users,
        where('username', '>=', search),
        where('username', '<=', search + '\uf8ff')
      )
      const snap = await list(starts_with)
      commit('setUsers', [...getters.getUsers, ...hydrate_list(snap)])
    })
  }
}
export const state = () => ({
  users: [],
  rUsers: [],
  sUsers: [],
  user: {},
  unique: {},
  unique_email: true,
  userIds: [],
  projects: [],
  userDeats: {},
  companies: [],
  photos: [],
  headshot: {}
})
export const mutations = {
  setUser(state, user) {
    state.user = user
  },
  setUsers(state, users) {
    state.users = users
  },
  setSponsorUsers(state, users) {
    state.users = users
  },
  setResearchUsers(state, users) {
    state.users = users
  },
  setUsersByIds(state, users) {
    state.users = users
  },
  resetUser(state) {
    state.user = null
  },
  username_status(state, unique) {
    state.unique = unique
  },
  email_status(state, unique_email) {
    state.unique_email = unique_email
  },
  setProjectUserIds(state, userIds) {
    state.projectUserIds = userIds
  },
  setCompanies(state, companies) {
    state.companies = companies
  },
  setProjects(state, projects) {
    state.projects = projects
  },
  setHeadshot(state, headshot) {
    state.headshot = headshot
  },
  setUserProjects(state, projects) {
    state.projects = projects
  },
  setUserIds(state, users) {
    state.userIds = users
  },
  setUserDeats(state, userDeats) {
    state.userDeats = userDeats
  },
  setPhotos(state, photos) {
    state.photos = photos
  }
}
export const getters = {
  username_status: state => state.unique,
  email_status: state => state.unique_email,
  getUsers: state => state.users,
  getSponsorUsers: state => state.users,
  getResearchUsers: state => state.users,
  getUser: state => state.user,
  getUserDeats: state => state.userDeats,
  getHeadshot: state => state.headshot,
  getPhotos: state => state.photos
}

export const add_participant_to_project = async (parp_id, project_id) => {
  console.log('add_participant_to_project', parp_id, project_id)
  const user = doc(`users/${parp_id}`)
  const project = await load(doc(`projects/${project_id}`))
  let parps = project.data().participants
  if (!parps) parps = []
  parps.push({ status: 'Approved', user })
  parps = dedupe_parps(parps)

  await update(project.ref, { participants: parps })
}
export const hydrate_user = async mobile =>
  (await list(by_phone(mobile))).docs[0]
export const by_phone = mobile => query(users, where('phone', '==', mobile))
export const by_username = username =>
  query(users, where('username', '==', username))
