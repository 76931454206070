export const clone = obj => JSON.parse(JSON.stringify(obj))
export const sleep = (ms = 1000) => new Promise(r => setTimeout(r, ms))
export let timer = null

// https://www.javascripttutorial.net/array/javascript-remove-duplicates-from-array/
export const dedupe = duplicates => [
  ...new Map(duplicates.map(m => [m.id, m])).values()
]

export const debounce = (func, interval = 1500) => {
  if (timer) clearTimeout(timer)
  timer = setTimeout(() => {
    func.apply(this)
  }, interval)
}

export const colors = {
  '--v-primary-base': '#51b4cd',
  '--v-primary-lighten5': '#e6ffff',
  '--v-primary-lighten4': '#c8ffff',
  '--v-primary-lighten3': '#abffff',
  '--v-primary-lighten2': '#8decff',
  '--v-primary-lighten1': '#70d0e9',
  '--v-primary-darken1': '#2f99b1',
  '--v-primary-darken2': '#007f97',
  '--v-primary-darken3': '#00667d',
  '--v-primary-darken4': '#004e64',
  '--v-secondary-base': '#de8244',
  '--v-secondary-lighten5': '#ffffc8',
  '--v-secondary-lighten4': '#fff0ac',
  '--v-secondary-lighten3': '#ffd491',
  '--v-secondary-lighten2': '#ffb877',
  '--v-secondary-lighten1': '#fd9c5d',
  '--v-secondary-darken1': '#c0682b',
  '--v-secondary-darken2': '#a24f12',
  '--v-secondary-darken3': '#843700',
  '--v-secondary-darken4': '#681f00',
  '--v-accent-base': '#f5c242',
  '--v-accent-lighten5': '#ffffce',
  '--v-accent-lighten4': '#ffffb1',
  '--v-accent-lighten3': '#ffff95',
  '--v-accent-lighten2': '#fffa79',
  '--v-accent-lighten1': '#ffde5e',
  '--v-accent-darken1': '#d6a724',
  '--v-accent-darken2': '#b88d00',
  '--v-accent-darken3': '#9b7300',
  '--v-accent-darken4': '#7e5b00'
}

export const chart_colors = () => {
  return [
    colors['--v-primary-base'],
    colors['--v-secondary-base'],
    colors['--v-accent-base'],

    colors['--v-primary-darken2'],
    colors['--v-secondary-darken2'],
    colors['--v-accent-darken1'],

    colors['--v-primary-lighten2'],
    colors['--v-secondary-lighten2'],
    colors['--v-accent-lighten1'],

    colors['--v-primary-lighten3'],
    colors['--v-secondary-lighten3'],
    colors['--v-accent-lighten2']
  ]
}
