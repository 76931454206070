import {
  projects,
  where,
  doc,
  load,
  list,
  update,
  file_remove,
  file_upload
} from '@/plugins/serverless.js'
import { load_photos } from '@/plugins/utils'
export const actions = {
  async load_files({ commit }, pyld) {
    var filz = []
    if (pyld.opt == 'Project') {
      const snap = await load(doc(`projects/${pyld.id}`))
      if (snap.exists()) filz = snap.data().files
    } else if (pyld.opt == 'User') {
      const snap = await list(
        projects,
        where('userIds', 'array-contains', pyld.id)
      )
      if (!snap.empty) {
        snap.forEach(doc => {
          filz.push(...doc.data().files)
        })
      }
    } else if (pyld.opt == 'Company') {
      let pIds = []
      const snap = await load(`companies/${pyld.id}`)
      if (snap.exists()) {
        let pl = []
        snap.forEach(doc => {
          pl = doc.data().projects
          pl.forEach(itm => {
            if (!pIds.includes(itm)) {
              pIds.push(itm)
            }
          })
        })
      }
      const snp = await list(projects, where('id', 'in', pIds))
      if (!snp.empty) {
        let fl = []
        snp.forEach(doc => {
          fl = doc.data().files
          fl.forEach(itm => {
            filz.push(itm)
          })
        })
      }
    }
    commit('set_files', filz)
  },
  async upload_link(ignore, pyld) {
    var url = pyld.url
    var pId = pyld.pId
    var name = pyld.name
    var filz = []
    const snap = await load(`projects/${pId}`)
    if (snap.exists()) filz = snap.data().files
    if (!filz) filz = []
    let nf = { name, url, type: 'url', size: 0 }
    filz.push(nf)
    update(doc(`projects/${pId}`), { files: filz })
  },
  async upload_file(ignore, pyld) {
    var pId = pyld.projectId
    var file = pyld.file
    var filz = pyld.file
    const path = `codi/project_files/${pId}/${file.name}`
    var duplicate = false
    const metadata = { contentType: 'image/jpg' }
    const url = file_upload(path, pyld.file, metadata)
    const snap = await load(`projects/${pId}`)
    if (snap.exists()) {
      filz = snap.data().files
      filz.forEach(f => {
        if (url == f.url || file.name == f.name) {
          duplicate = true
        }
      })
      if (!duplicate) {
        if (!filz) filz = []
        var nf = {
          name: file.name,
          url: url,
          type: file.type,
          size: file.size
        }
        filz.push(nf)
        update(doc(`projects/${pId}`), { files: filz })
      }
    }
  },
  async delete_file(ignore, pyld) {
    var pId = pyld.projectId
    var file = pyld.file
    var filz = []
    if (file.type !== 'url')
      await file_remove(doc(`codi/project_files/${pId}/${file.name}`))
    const snap = await load(`projects/${pId}`)
    if (!snap?.empty && snap?.data().files) filz = snap.data().files
    let fl = []
    filz.forEach(f => {
      if (f.name !== file.name) {
        fl.push(f)
      }
    })
    update(doc(`projects/${pId}`), { files: fl })
  },
  async load_photos({ commit }, pyld) {
    const photos = await load_photos(pyld)
    commit('set_photos', photos)
  },
  async upload_photo(ignore, pyld) {
    const path = `codi/${pyld.type}_photos/${pyld.id}/${Date.now()}.jpg`
    const metadata = { contentType: 'image/jpg' }
    return file_upload(path, pyld.file, metadata)
  },
  async remove_photo(ignore, path) {
    return file_remove(path)
  }
}
export const state = () => ({
  files: [],
  photos: []
})
export const mutations = {
  set_files(state, files) {
    state.files = files
  },
  set_photos(state, photos) {
    state.photos = photos
  }
}
export const getters = {
  get_files: state => state.files,
  get_photos: state => state.photos
}
