
  import Snackbar from '@/components/Snackbar'
  import common from '@/mixins/common'
  export default {
    components: { Snackbar },
    mixins: [common],
    layout: 'authorize',
    async mounted() {
      this.log('app-guest', navigator.userAgent, document.referrer)
      this.setup_color_scheme()
    }
  }
