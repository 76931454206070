export const default_markdown = `# Main title for your prompt
Here is some sample text for your project. it's just text by itself. you should use this for most things
## secondary header
### Third header
#### Fourth header
##### Fifth is like a subtitle
###### Sixth is like subscript
---
3 dashes makes a nice section seperator
paragraphs can be stacked next to each other

Empty line creates space between paragraphs

*italicized*

**Bold**

- spaced dash makes a list
	- lists can have nested lists

---
**Make sure you save  your project often**`

export const dedupe_parps = duplicates => [
  ...new Map(duplicates.map(m => [m.user.id, m])).values()
]
