import { doc, load } from '@/plugins/serverless.js'
const id = 'fcZX9VSHSfxWNHiHnK1R'
export const hydrate_list = snapshot => {
  const items = []
  if (!snapshot.empty) {
    snapshot.forEach(doc => {
      const item = doc.data()
      item.id = doc.id // TODO: remove this as snapshot provides access  to ref and id
      // item.snapshot = doc
      items.push(item)
    })
  }
  return items
}
export const hydrate_names = snapshot => {
  const names = []
  if (!snapshot.empty) {
    snapshot.forEach(doc => {
      names.push(doc.data().name)
    })
  }
  return names
}

export const actions = {
  async load_features({ commit }) {
    const snap = await load(doc(`utils/${id}`))
    if (snap.exists()) commit('set_features', snap.data().features)
  },
  async load_interests({ commit }) {
    const snap = await load(doc(`utils/${id}`))
    if (snap.exists()) commit('set_interests', snap.data().interests)
  }
}
export const state = () => ({
  interests: null,
  features: null
})
export const mutations = {
  set_interests(state, interests) {
    state.interests = interests
  },
  set_features(state, new_features) {
    state.features = new_features
  }
}
export const getters = {
  get_interests: state => state.interests,
  get_features: state => state.features
}
