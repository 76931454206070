export const Snackbar = () => import('../../components/Snackbar.vue' /* webpackChunkName: "components/snackbar" */).then(c => wrapFunctional(c.default || c))
export const Photos = () => import('../../components/photos.vue' /* webpackChunkName: "components/photos" */).then(c => wrapFunctional(c.default || c))
export const ExpressionAsArticle = () => import('../../components/expression/as-article.vue' /* webpackChunkName: "components/expression-as-article" */).then(c => wrapFunctional(c.default || c))
export const ExpressionAsFigurePrediction = () => import('../../components/expression/as-figure-prediction.vue' /* webpackChunkName: "components/expression-as-figure-prediction" */).then(c => wrapFunctional(c.default || c))
export const ChartsDemographicsPie = () => import('../../components/charts/demographics-pie.vue' /* webpackChunkName: "components/charts-demographics-pie" */).then(c => wrapFunctional(c.default || c))
export const ChartsParticipantsBar = () => import('../../components/charts/participants-bar.vue' /* webpackChunkName: "components/charts-participants-bar" */).then(c => wrapFunctional(c.default || c))
export const ChartsPredictionStats = () => import('../../components/charts/prediction-stats.vue' /* webpackChunkName: "components/charts-prediction-stats" */).then(c => wrapFunctional(c.default || c))
export const ChartsProjectStatus = () => import('../../components/charts/project-status.vue' /* webpackChunkName: "components/charts-project-status" */).then(c => wrapFunctional(c.default || c))
export const ChartsStoriesBar = () => import('../../components/charts/stories-bar.vue' /* webpackChunkName: "components/charts-stories-bar" */).then(c => wrapFunctional(c.default || c))
export const ChartsSurveyPie = () => import('../../components/charts/survey-pie.vue' /* webpackChunkName: "components/charts-survey-pie" */).then(c => wrapFunctional(c.default || c))
export const ChartsWordCloud = () => import('../../components/charts/word-cloud.vue' /* webpackChunkName: "components/charts-word-cloud" */).then(c => wrapFunctional(c.default || c))
export const CompanyAsForm = () => import('../../components/company/as-form.vue' /* webpackChunkName: "components/company-as-form" */).then(c => wrapFunctional(c.default || c))
export const DemographicsChoose = () => import('../../components/demographics/choose.vue' /* webpackChunkName: "components/demographics-choose" */).then(c => wrapFunctional(c.default || c))
export const DemographicsConfigure = () => import('../../components/demographics/configure.vue' /* webpackChunkName: "components/demographics-configure" */).then(c => wrapFunctional(c.default || c))
export const PlanAsFormDemographics = () => import('../../components/plan/as-form-demographics.vue' /* webpackChunkName: "components/plan-as-form-demographics" */).then(c => wrapFunctional(c.default || c))
export const PlanAsFormGoals = () => import('../../components/plan/as-form-goals.vue' /* webpackChunkName: "components/plan-as-form-goals" */).then(c => wrapFunctional(c.default || c))
export const PlanAsFormHypothesis = () => import('../../components/plan/as-form-hypothesis.vue' /* webpackChunkName: "components/plan-as-form-hypothesis" */).then(c => wrapFunctional(c.default || c))
export const PlanAsFormOutcomes = () => import('../../components/plan/as-form-outcomes.vue' /* webpackChunkName: "components/plan-as-form-outcomes" */).then(c => wrapFunctional(c.default || c))
export const PlanAsFormQuestions = () => import('../../components/plan/as-form-questions.vue' /* webpackChunkName: "components/plan-as-form-questions" */).then(c => wrapFunctional(c.default || c))
export const ProjectAsArticleTally = () => import('../../components/project/as-article-tally.vue' /* webpackChunkName: "components/project-as-article-tally" */).then(c => wrapFunctional(c.default || c))
export const ProjectAsBreadcrumb = () => import('../../components/project/as-breadcrumb.vue' /* webpackChunkName: "components/project-as-breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const ProjectAsForm = () => import('../../components/project/as-form.vue' /* webpackChunkName: "components/project-as-form" */).then(c => wrapFunctional(c.default || c))
export const ProjectAsTableFiles = () => import('../../components/project/as-table-files.vue' /* webpackChunkName: "components/project-as-table-files" */).then(c => wrapFunctional(c.default || c))
export const ProjectAsTableParticipants = () => import('../../components/project/as-table-participants.vue' /* webpackChunkName: "components/project-as-table-participants" */).then(c => wrapFunctional(c.default || c))
export const StoryAsArticleTally = () => import('../../components/story/as-article-tally.vue' /* webpackChunkName: "components/story-as-article-tally" */).then(c => wrapFunctional(c.default || c))
export const StoryAsDetails = () => import('../../components/story/as-details.vue' /* webpackChunkName: "components/story-as-details" */).then(c => wrapFunctional(c.default || c))
export const StoryAsFigureTally = () => import('../../components/story/as-figure-tally.vue' /* webpackChunkName: "components/story-as-figure-tally" */).then(c => wrapFunctional(c.default || c))
export const StoryAsForm = () => import('../../components/story/as-form.vue' /* webpackChunkName: "components/story-as-form" */).then(c => wrapFunctional(c.default || c))
export const StoryAsList = () => import('../../components/story/as-list.vue' /* webpackChunkName: "components/story-as-list" */).then(c => wrapFunctional(c.default || c))
export const StoryAsTable = () => import('../../components/story/as-table.vue' /* webpackChunkName: "components/story-as-table" */).then(c => wrapFunctional(c.default || c))
export const StoryEditor = () => import('../../components/story/editor.vue' /* webpackChunkName: "components/story-editor" */).then(c => wrapFunctional(c.default || c))
export const UserAsFormMobile = () => import('../../components/user/as-form-mobile.vue' /* webpackChunkName: "components/user-as-form-mobile" */).then(c => wrapFunctional(c.default || c))
export const UserForm = () => import('../../components/user/form.vue' /* webpackChunkName: "components/user-form" */).then(c => wrapFunctional(c.default || c))
export const UserView = () => import('../../components/user/view.vue' /* webpackChunkName: "components/user-view" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
